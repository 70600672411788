// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/atlassian/pipelines/agent/build/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-firebase-index-js": () => import("/opt/atlassian/pipelines/agent/build/src/pages/firebase/index.js" /* webpackChunkName: "component---src-pages-firebase-index-js" */),
  "component---src-pages-impressum-js": () => import("/opt/atlassian/pipelines/agent/build/src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("/opt/atlassian/pipelines/agent/build/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-tutorials-scratch-js": () => import("/opt/atlassian/pipelines/agent/build/src/pages/tutorials/scratch.js" /* webpackChunkName: "component---src-pages-tutorials-scratch-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/atlassian/pipelines/agent/build/.cache/data.json")

